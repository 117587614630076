// Schedule.vue // https://demo9140832.mockable.io/calendar-events //
https://www.npmjs.com/package/vue-full-calendar

<template>
	<div class="container-fluid">
		<app-schedule-cal></app-schedule-cal>
	</div>
</template>

<script>
import ScheduleCalendar from "./ScheduleCalendar.vue";

export default {
	name: "Schedule",
	data() {
		return {
			msg: "Welcome to Your Vue.js App"
		};
	},
	activated() {
		window.scrollTo(0, 0);
	},
	components: {
		"app-schedule-cal": ScheduleCalendar
	}
};
</script>

<style lang="scss">
.fc {
	border-bottom: 100px solid transparent;
}

.vdp-datepicker__calendar .cell.today {
	border: 1px solid #4bd;
	color: #4bd;
}
.vdp-datepicker__calendar .cell.selected {
	//border: 1px solid #4bd;
	color: white;
}

.vdp-datepicker__calendar {
	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
}
</style>
