<template>
	<section class="pb-5" style="min-height: 100vh;">
		<pre>colorKey: {{ colorKey }}</pre>

		<nav
			:class="{ show: showFooterUndo }"
			class="showFooterUndo navbar navbar-dark justify-content-between fixed-bottom p-4"
			style="bottom: 25px; left: 25px; right: 25px;"
		>
			<h5 class="_text-white">
				<i class="material-icons">face</i>Changes Saved.
			</h5>
			<a href @click.prevent="undoLastScheduleChange()" class="btn">Undo</a>
		</nav>

		<div class="row justify-content-between">
			<div v-if="!gapMode" class="col-sm">
				<h1>Schedule RTs</h1>
				<p class="lead text-muted">
					Manage Respiratory Therapists' work schedule.
				</p>
			</div>
			<div v-if="gapMode" class="col-sm">
				<h1>Schedule Gaps</h1>
				<p class="lead text-muted">
					Claim time blocks that you are qualified to fill.
				</p>
			</div>
			<div class="col-auto mr-auto">
				<a
					href=""
					@click.prevent="scheduleSubscribeModal.show = true"
					class="btn btn-outline-secondary mb-3 ml-1"
				>
					<i class="material-icons">event_available</i>Subscribe
				</a>
				<a
					href
					v-on:click.prevent="newEventModal"
					class="btn btn-outline-primary mb-3 ml-1"
				>
					<i class="material-icons">&#xE145;</i>New Block
				</a>
			</div>
		</div>

		<!-- FILTERS ENVIRONMENT -->
		<div
			class="btn-group mb-1"
			role="group"
			v-show="loggedInUser.rtnow_admin || loggedInUser.rtnow_rt"
		>
			<button
				v-on:click.stop.prevent="showEnvironment = !showEnvironment"
				id="btnGroupDropEnv"
				type="button"
				class="btn btn-secondary dropdown-toggle"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
			>
				<small class="btn-label">Environment</small>

				<span v-if="scheduleFilter.environment === 'rtnow'">rtNOW</span>
				<span v-if="scheduleFilter.environment === 'facility'">Facility</span>
			</button>
			<div
				class="dropdown-menu"
				:class="{ show: showEnvironment }"
				v-on-clickaway="hideEnvironment"
			>
				<!-- <a  href style="opacity: .4"
							@click.prevent="scheduleFilter.environment = 'All'"
							:class="{'_active': 'All' == scheduleFilter.environment}"
							class="dropdown-item">
						All
				</a>-->
				<a
					href
					@click.prevent="scheduleFilter.environment = 'rtnow'"
					:class="{ active: 'rtnow' === scheduleFilter.environment }"
					class="dropdown-item"
					>rtNOW</a
				>
				<a
					href
					@click.prevent="scheduleFilter.environment = 'facility'"
					:class="{ active: 'facility' === scheduleFilter.environment }"
					class="dropdown-item"
					>Facility</a
				>
			</div>
		</div>

		<!-- FILTER TIMEZONE -->
		<div class="btn-group mb-1" role="group">
			<button
				v-on:click.stop.prevent="showTZ = !showTZ"
				id="timeZoneBtn"
				type="button"
				class="btn btn-secondary dropdown-toggle"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
			>
				<small class="btn-label">Timezone</small>
				{{ eventModalOptions.timezone }}
				<!-- scheduleFilter.timezone -->
			</button>
			<div
				class="dropdown-menu"
				:class="{ show: showTZ }"
				v-on-clickaway="hideTZ"
			>
				<!-- <a class="dropdown-item" href="#">Dropdown link</a>
				<a class="dropdown-item" href="#">Dropdown link</a>-->
				<a
					href
					@click.prevent="selectTimeZone(b)"
					v-for="(a, b) in eventModalOptions.timezones"
					:class="{ active: b === eventModalOptions.timezone }"
					class="dropdown-item"
					:key="b"
				>
					{{ a }}
					<span style="opacity: .4">{{ b }}</span>
				</a>
				<!-- :class="{'active': tz.timezone == scheduleFilter.timezone}" -->
			</div>
		</div>

		<div
			v-if="facilitiesManageSchedule && facilitiesManageSchedule.length > 1"
			class="btn-group mb-1"
			role="group"
			v-show="scheduleFilter.environment !== 'rtnow'"
		>
			<button
				v-on:click.stop.prevent="showFacilities = !showFacilities"
				id="showFacilitiesBtn"
				type="button"
				class="btn btn-secondary dropdown-toggle"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
			>
				<small class="btn-label">facility</small>
				{{ scheduleFilter.facility_name }}
			</button>
			<div
				class="dropdown-menu"
				:class="{ show: showFacilities }"
				v-on-clickaway="hideFacilities"
			>
				<!-- <a class="dropdown-item" href="#">Dropdown link</a>
				<a class="dropdown-item" href="#">Dropdown link</a>-->
				<a
					href
					@click.prevent="filterFacility(false)"
					class="dropdown-item"
					style="opacity: .4"
					>All Facilities</a
				>
				<a
					href
					@click.prevent="filterFacility(f)"
					v-for="f in eventModalOptions.facilities"
					class="dropdown-item"
					:key="'eventModalOptions-facilities-' + f.id"
					>{{ f.name }}</a
				>
			</div>
		</div>

		<div class="btn-group mb-1 rtFilter" role="group">
			<button
				v-on:click.stop.prevent="showRts = !showRts"
				id="btnGroupDrop1"
				type="button"
				class="btn btn-secondary dropdown-toggle"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
			>
				<small class="btn-label">Respiratory Therapist</small>
				{{ scheduleFilter.rt_name }}
			</button>
			<div
				class="dropdown-menu"
				:class="{ show: showRts }"
				v-on-clickaway="hideRts"
			>
				<!-- <a class="dropdown-item" href="#">Dropdown link</a>
				<a class="dropdown-item" href="#">Dropdown link</a>-->
				<a
					href
					@click.prevent="filterRts(false)"
					class="dropdown-item"
					style="opacity: .4"
				>
					<i :class="'filterDot fc-outline'"></i>All RTs
				</a>

				<a
					href
					@click.prevent="filterRts(rt)"
					v-for="rt in eventModalOptions.filteredRTs"
					class="dropdown-item"
					:key="'eventModalOptions-filteredRTs-' + rt.id"
				>
					<i :class="'filterDot fc-' + rt.color"></i>
					{{ rt.first_name }} {{ rt.last_name }}
				</a>
			</div>
		</div>



		<div class="btn-group mb-1 rtFilter" role="group">
			<button
				v-on:click.stop.prevent="showPods = !showPods"
				id="btnGroupDrop1"
				type="button"
				class="btn btn-secondary dropdown-toggle"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
			>
				<small class="btn-label">Pod</small>
				{{ eventModalOptions.pod }}
			</button>
			<div
				class="dropdown-menu"
				:class="{ show: showPods }"
				v-on-clickaway="hidePods"
			>
			<!-- <a class="dropdown-item" href="#">Dropdown link</a>
			<a class="dropdown-item" href="#">Dropdown link</a>-->
<!--			<a-->
<!--				href-->
<!--				@click.prevent="filterPods(false)"-->
<!--				class="dropdown-item"-->
<!--				style="opacity: .4"-->
<!--			>-->
<!--			</a>-->

				<a
					href
					@click.prevent="filterPods(pod)"
					v-for="pod in eventModalOptions.pods"
					class="dropdown-item"
					:class="{ active: pod.name === eventModalOptions.pod}"
					:key="'eventModalOptions-filteredPods-' + pod.name"
				>
					<i :class="'filterDot fc-'"></i>
					{{ pod.name }} - {{ pod.states.join(', ') }}
				</a>
			</div>
		</div>





		<div class="btn-group mt-2" style="float: right;">
			<a
				href
				v-on:click.prevent="toggleGapMode(false)"
				class="btn mb-3"
				:class="{
					'btn-secondary active': !gapMode,
					'btn-outline-secondary': gapMode
				}"
			>
				<i class="material-icons">date_range</i>Schedule
			</a>
			<a
				href
				v-on:click.prevent="toggleGapMode(true)"
				class="btn mb-3"
				:class="{
					'btn-secondary active': gapMode,
					'btn-outline-secondary': !gapMode
				}"
			>
				<i class="material-icons">extension</i>Gaps
			</a>
		</div>

		<div style="clear: both;">{{ events.length }} events</div>

		<app-modal
			:modal="scheduleSubscribeModal"
			@close-modal="scheduleSubscribeModal.show = false"
		>
			<template slot="title">Subscribe to this Calendar</template>

			<form>
				<small class="text-muted pb-3">Public address in iCal format</small>

				<div class="form-group">
					<label style="display: none" for="calSubPersonal"></label>
					<textarea
						v-model="eventModalOptions.calendar_subscriptions.personal"
						type="text"
						name="name"
						placeholder=""
						style="height: 80px"
						class="form-control form-control-sm mt-1"
						id="calSubPersonal"
					>
					</textarea>
				</div>
			</form>

			<template slot="footer">
				<button
					type="button"
					class="btn btn-outline-secondary"
					data-dismiss="modal"
					v-on:click.prevent="scheduleSubscribeModal.show = false"
				>
					Close
				</button>
				<!--				<button-->
				<!--						type="button"-->
				<!--						class="btn btn-danger"-->
				<!--						v-on:click.prevent="removeAlert(removeAlertModal.id, true)"-->
				<!--						style="margin-right: .25em"-->
				<!--				>-->
				<!--					Remove-->
				<!--				</button>-->
			</template>
		</app-modal>

		<app-modal :modal="eventModal">
			<span slot="title">Schedule Block</span>

			<form v-on:submit.prevent="saveEvent">
				<div class="form-group">
					<label>Start Date</label>

					<span class>
						<code>{{ eventModal.id }}</code>
						<code>{{ eventModal.mode }}</code>
					</span>

					<datepicker
						v-model="eventModal.startDate"
						:format="'MM/dd/yyyy'"
						input-class="form-control form-control-lg"
					></datepicker>
				</div>

				<div class="form-group">
					<label>Start Time</label>

					<div class="input-group">
						<select
							class="custom-select form-control-lg"
							v-model="eventModal.start.h"
							id="eventModalStartHour"
						>
							<option v-for="n in 12" :value="n" :key="'start-hour-' + n">{{
								n
							}}</option>
						</select>

						<div
							class="input-group-prepend"
							style="border-left: none; margin-left: -1px;"
						>
							<span class="input-group-text">:</span>
						</div>

						<!-- <input type="number" placeholder="00" v-model="eventModal.start.m" class="form-control form-control-lg"/> -->
						<select
							class="custom-select form-control-lg"
							v-model="eventModal.start.m"
							id="eventModalStartMinute"
						>
							<option value="00">00</option>
							<option value="15">15</option>
							<option value="30">30</option>
							<option value="45">45</option>
						</select>

						<select
							class="custom-select form-control-lg"
							id="eventModalStartA"
							v-model="eventModal.start.a"
						>
							<option value="AM">AM</option>
							<option value="PM">PM</option>
						</select>
					</div>
				</div>

				<div class="form-group" :class="{ hasErrors: isAfterError }">
					<label>End Date</label>

					<code>{{ eventModal.id }}</code>
					<code>{{ eventModal.mode }}</code>
					<!-- <div>isAfterErrorLive: {{isAfterErrorLive}}</div> -->

					<datepicker
						:class="{ sweller: EODsweller }"
						v-model="eventModal.endDate"
						:format="'MM/dd/yyyy'"
						input-class="form-control form-control-lg"
					></datepicker>
				</div>

				<div class="form-group" :class="{ hasErrors: isAfterError }">
					<label class="pt-3">End Time</label>
					<button
						type="submit"
						@click.stop.prevent="setEOD"
						class="btn btn-secondary float-right"
						title="End of Day"
					>
						EOD
					</button>

					<div class="input-group" :class="{ sweller: EODsweller }">
						<select
							class="custom-select form-control-lg"
							id="eventModalEndHour"
							v-model="eventModal.end.h"
						>
							<option v-for="n in 12" :value="n" :key="'end-hour-' + n">{{
								n
							}}</option>
						</select>

						<div
							class="input-group-prepend"
							style="border-left: none; margin-left: -1px;"
						>
							<span class="input-group-text" id="basic-addon1">:</span>
						</div>

						<select
							class="custom-select form-control-lg"
							v-model="eventModal.end.m"
							id="eventModalEndMinute"
						>
							<option value="00">00</option>
							<option value="15">15</option>
							<option value="30">30</option>
							<option value="45">45</option>
						</select>

						<select
							class="custom-select form-control-lg"
							id="inputGroupSelect01"
							v-model="eventModal.end.a"
						>
							<option value="AM">AM</option>
							<option value="PM">PM</option>
						</select>
					</div>
				</div>

				<div
					class="form-group"
					v-show="eventModalOptions.rtNOW_rt_manage_schedule.length"
				>
					<label>Environment</label>

					<select
						id="environment"
						name="environment"
						class="form-control form-control-lg custom-select"
						v-model="eventModal.environment"
					>
						<option value>- Select Environment -</option>
						<option value="rtnow">rtNOW</option>
						<option value="facility">Facility</option>
					</select>
				</div>

				<div>
					<div v-show="eventModal.environment == 'facility'" class="form-group">
						<label>Facility</label>

						<select
							id="facility"
							name="facility"
							class="form-control form-control-lg custom-select"
							v-model="eventModal.facility"
						>
							<option value>- Select Facility -</option>
							<option
								v-for="facility in facilitiesManageSchedule"
								:value="facility.id"
								v-show="facility.manage_schedule || facility.self_schedule"
								:key="'facility-event-' + facility.id"
								>{{ facility.name }}</option
							>
						</select>
					</div>

					<div class="form-group">
						<label>Respiratory Therapist</label>

						<pre>usersForFacility: {{ usersForFacility }}</pre>

						<select
							id="rt"
							name="rt"
							class="form-control form-control-lg custom-select"
							v-model="eventModal.rt"
						>
							<option value>- Select Respiratory Therapist -</option>
							<option
								v-for="user in usersForFacility"
								:value="user.id"
								:key="'user-id-' + user.id"
								>{{ user.first_name + " " + user.last_name }}</option
							>
						</select>
					</div>

					<div class="form-group">

						<div class="form-control-lg" style="padding-left: 0" >	{{ eventModal.pod }}</div>


						<pre>usersForFacility: {{ usersForFacility }}</pre>



<!--						<select-->
<!--							id="rt"-->
<!--							name="rt"-->
<!--							class="form-control form-control-lg custom-select"-->
<!--							v-model="eventModal.pod"-->
<!--						>-->
<!--							<option value>- Select Pod -</option>-->
<!--							<option-->
<!--							v-for="pod in eventModalOptions.pods"-->
<!--							:value="pod"-->
<!--							:key="'pod-' + pod"-->
<!--							>-->
<!--							{{ pod.name }} - {{ pod.states.join(', ') }}-->
<!--							</option>-->
<!--						</select>-->

					</div>

				</div>

				<div class="errors">
					<p
						v-for="(key, value) in validationErrors"
						:key="'validationErrors-' + key"
					>
						{{ key }} {{ value }}
					</p>
				</div>
			</form>

			<template slot="footer">
				<button
					style="position: absolute; left: 1em;"
					v-if="eventModal.id"
					type="button"
					class="btn btn-danger"
					data-dismiss="modal"
					v-on:click.prevent="deleteEvent(eventModal.id, false)"
				>
					Delete Event
				</button>
				<button
					type="button"
					class="btn btn-secondary"
					data-dismiss="modal"
					v-on:click.prevent="eventModal.show = false"
				>
					Close
				</button>
				<button
					type="submit"
					v-on:click.prevent="saveEvent"
					class="btn btn-primary"
				>
					Save
				</button>
			</template>
		</app-modal>

		<div
			id="event_tool_tip"
			v-if="eventToolTip.show"
			:style="eventToolTipStyles"
		>
			<section>{{ eventToolTip.text }}</section>
			<span class="after"></span>
		</div>

		<div style="min-height: 100vh;" class="mb-5 mt-2 pb-5">
			<full-calendar :config="config" :events="events" />
		</div>
	</section>
</template>

<script>
import moment from "moment";

import Datepicker from "vuejs-datepicker";

//import FullCalendar from "@vue-full-calendar";
//import { FullCalendar } from "vue-full-calendar";
import { FullCalendar } from "vue-full-calendar";

//import "fullcalendar/dist/fullcalendar.css";

import { mapGetters, mapActions } from "vuex";

import { directive as onClickaway } from "vue-clickaway";

export default {
	name: "ScheduleCalendar",
	directives: {
		onClickaway: onClickaway
	},
	data() {
		return {
			// do I show the gaps or show the schedule
			gapMode: false,

			//optionsLoaded: false,

			eventToolTip: {
				x: 10,
				y: 10,
				show: true,
				text: ""
			},

			EODsweller: false,

			isAfterError: false,

			showFooterUndo: false,
			undoLastScheduleChangeMethod: function() {},

			eventToUndo: {},

			st: null, // for setTimeout

			scheduleFilter: {
				environment: "facility",
				facility_id: null,
				facility_name: "All Facilities",
				client_id: null,
				rt_id: null,
				rt_name: "All RTs",
				timezone: null,
				optionsLoaded: false,
				pod: "",
			},

			showEnvironment: false,
			showTZ: false,
			showFacilities: false,
			showRts: false,
			showPods: false,

			validationErrors: [],
			showColors: false,

			colorKey: [],

			viewMonth: moment().format("MM"),
			viewYear: moment().format("YYYY"),

			eventModalOptions: {
				users: [],
				timezone: "",
				timezones: {},
				rtNOW_rt_manage_schedule: [],
				calendar_subscriptions: {
					personal: ""
				},
				pods: "", // all the pods
				pod: "", // pod the user is in
			},

			scheduleSubscribeModal: {
				show: false
			},

			// MODAL
			eventModal: {
				show: false,
				environment: "facility",
				id: "",
				rt: "",
				mode: "new",
				facility: "",
				date: moment().format("MM/DD/YYYY"), // today's date
				start: {
					h: 8,
					m: "00",
					a: "AM"
				},
				end: {
					h: 5,
					m: "00",
					a: "PM"
				},
				allDay: false,
				startDate: moment().format("MM/DD/YYYY"),
				endDate: moment().format("MM/DD/YYYY"),
				pod: "",
			},

			events: [],

			eventColor: "#378006", // default event bg color

			config: {
				columnHeaderFormat: "D ddd",

				selectHelper: true,

				defaultView: "agendaWeek",

				timeFormat: "h(:mm)t",

				fixedWeekCount: false,

				snapMinutes: 15,

				slotMinutes: 15,
				slotDuration: "00:15:00",

				themeSystem: "bootstrap4",

				prev: "my-chevron-left", // arrows when bootstrap4 is used
				next: "my-chevron-right",

				height: "parent",

				contentHeight: "auto",

				allDaySlot: false, //Determines if the "all-day" slot is displayed at the top of the calendar.

				nextDayThreshold: "08:00", // When an event's end time spans into another day, the minimum time it must be in order for it to render as if it were on that day.

				header: {
					left: "prev,next today",
					center: "title",
					right: "month,agendaWeek,listMonth" //agendaDay
				},

				viewRender: view => {
					//handle event

					console.log("view", view);
					console.log("view.start", view.start);
					//console.log('element', element);

					// eslint-disable-next-line no-undef
					var h = $(".fc-header-toolbar h2").html();

					var dateArray = h.split(" ");

					console.log("h", h);
					console.log("dateArray", dateArray);

					var viewYear = moment(view.start).format("YYYY");
					var viewMonth = moment(dateArray[0] + " 01 " + viewYear).format("MM");

					this.viewMonth = viewMonth;
					this.viewYear = viewYear;

					console.log(this.viewMonth);
				},

				// eventRender: (event, element) => {
				// 	console.log("eventRender----", event);
				// 	console.log("element", element);
				// },

				// for tooltip
				// https://github.com/CroudTech/vue-fullcalendar/issues/118
				eventMouseover: (event, element, view) => {
					//console.log('eventMouseover----',event)
					console.log("eventMouseover---- ", event.title);

					//var e = event || window.event;

					this.eventToolTip.text =
						event.description || "event[].description not populated!!!";
					this.eventToolTip.x = element.originalEvent.clientX;
					this.eventToolTip.y = element.originalEvent.pageY;
					this.eventToolTip.show = true;
					console.log("clientX---- ", element.originalEvent.clientX);
					console.log("clientY---- ", element.originalEvent.clientY);
					console.log("element", element);
					console.log("view", view);
				},
				// eslint-disable-next-line no-unused-vars
				eventMouseout: (calEvent, jsEvent) => {
					this.eventToolTip.show = false;

					//$(this).css('z-index', 8);
					//$('.tooltipevent').remove();
				},

				/*  FOR EDITING EXISTING EVENTS */

				// user clicks an existing event
				// they intend to view or edit it
				// eslint-disable-next-line no-unused-vars
				eventClick: (event, jsEvent, view) => {
					console.log(event.title + " was clicked!!! ");
					console.log("event.environment: " + event.environment);

					if (event.editable != false) {
						this.$set(this, "eventModal", event);

						//this.eventModal.show = true;

						this.$set(this.eventModal, "show", true);

						this.eventModal.mode = "edit";

						//this.eventModal.id = event.id;
						//this.eventModal.rt = event.rt;
						//this.eventModal.environment = event.environment;
						//this.eventModal.facility = event.facility;

						(this.eventModal.startDate = moment(event.start).format(
							"MM/DD/YYYY"
						)),
							(this.eventModal.endDate = moment(event.end).format(
								"MM/DD/YYYY"
							)),
							(this.eventModal.start = {
								h: moment(event.start).format("h"),
								m: moment(event.start).format("mm"),
								a: moment(event.start).format("A")
							});
						this.eventModal.end = {
							h: moment(event.end).format("h"),
							m: moment(event.end).format("mm"),
							a: moment(event.end).format("A")
						};

						//this.$set(this.eventModal, 'environment', event.environment);
					}

					console.log("event", event);
					console.log("eventModal", this.eventModal.environment);
					//console.log('jsEvent',jsEvent);
					//console.log('view',view);
				},

				// user stretches are shrinks the duration of an event
				// eslint-disable-next-line no-unused-vars
				eventResize: (event, delta, revertFunc, jsEvent, ui, view) => {
					console.log(event.title + " end is now " + event.end);

					this.updateEventAfterDrag(event);

					this.showFooterUndo = true;

					this.eventToUndo = event;

					this.undoLastScheduleChangeMethod = revertFunc;

					// if (!confirm("To undo this change, click Cancel.")) {
					// 	revertFunc(); // undo the last change to event duration
					// }
				},

				// user drags the entire event to a new place
				// eslint-disable-next-line no-unused-vars
				eventDrop: (event, delta, revertFunc, jsEvent, ui, view) => {
					console.log(
						"After dragging, " + event.title + " end is now " + event.end
					);

					this.updateEventAfterDrag(event);

					this.showFooterUndo = true;

					this.eventToUndo = event;

					this.undoLastScheduleChangeMethod = revertFunc;

					// if (!confirm("After dragging, to undo this change, click Cancel.")) {
					// 	revertFunc(); // undo the last change to event duration
					//
					// }
				},

				/*  FOR NEW EVENTS */

				// user simply clicks to add a new event
				// eslint-disable-next-line no-unused-vars
				dayClick: (date, jsEvent, view) => {
					// change the day's background color just for fun
					//$(this).css('background-color', 'red');
				},

				// user clicks and drags to add a new event with specific duration
				select: (startDate, endDate) => {
					this.newEventModal(startDate, endDate);
				}
			}
		};
	},

	watch: {
		"eventModal.show"() {
			console.log("watch eventModal.show");

			if (this.eventModal.show) {
				//check for defaults if only one option exists

				// is there one facilty?

				if (this.facilitiesManageSchedule.length === 1) {
					console.log(
						"Just one Facility option for facilitiesManageSchedule..."
					);

					// what is the facility id of this one
					var facilityId = this.facilitiesManageSchedule[0].id;

					// set this as the defaults

					this.eventModal.facility = facilityId;
				}

				// determine the Environment

				// if(this.eventModal.facility){
				// 	this.eventModal.environment = 'facility';
				// }
			}
		},

		showFooterUndo() {
			// whn this is shown,
			// turn it off after 4 seconds
			try {
				clearInterval(this.st);
			} catch (err) {
				console.warn("no Interval to clear");
				console.log(err);
			}

			if (this.showFooterUndo) {
				this.st = setTimeout(() => {
					this.showFooterUndo = false;
				}, 7000);
			}
		},

		showTZ() {
			console.log("watch showTZ");
			if (this.showTZ) {
				this.showFacilities = false;
				this.showRts = false;
				this.showEnvironment = false;
			}
		},

		showFacilities() {
			console.log("watch showFacilities");
			if (this.showFacilities) {
				this.showTZ = false;
				this.showRts = false;
			}
		},

		showRts() {
			console.log("watch showRts");
			if (this.showRts) {
				this.showTZ = false;
				this.showFacilities = false;
			}
		},

		viewMonth: {
			handler() {
				console.log("watch: viewMonth");
				this.readEvents();
			},
			immediate: false
		},

		// deep watch
		scheduleFilter: {
			handler: function() {
				this.hideEnvironment();

				// default to all users that I can see
				this.eventModalOptions.filteredRTs = this.eventModalOptions.users;

				if (this.scheduleFilter.environment == "rtnow") {
					this.scheduleFilter.facility_id = null;
					this.scheduleFilter.facility_name = "All Facilities";
				}

				// change the rts in the filter dropdown//
				// based on facility selection or rtNOW environment

				if (this.scheduleFilter.facility_id) {
					const filteredRTsForFacility = this.eventModalOptions.users.filter(
						ele => {
							// rt_facilities[]
							//return (ele.id == this.scheduleFilter.facility_id);
							return ele.rt_facilities.includes(
								this.scheduleFilter.facility_id
							);
							//return true;
						}
					);

					this.eventModalOptions.filteredRTs = filteredRTsForFacility;
					//this.eventModalOptions.facilities =
				}

				// this.scheduleFilter.facility_id is null
				// no facility has been selected from filter
				if (!this.scheduleFilter.facility_id) {
					if (this.scheduleFilter.environment == "rtnow") {
						this.eventModalOptions.filteredRTs = this.viewablertNowRts;
					} else if (this.scheduleFilter.environment == "facility") {
						this.eventModalOptions.filteredRTs = this.viewableFacilityRTs;
					}
					//else { // all
					//this.eventModalOptions.filteredRTs = this.eventModalOptions.users;
					//this.eventModalOptions.filteredRTs = this.viewablertNowRts.concat(this.viewablertNowRts);
					//}
				}

				console.log("watch scheduleFilter");
				if (this.scheduleFilter.optionsLoaded) {
					this.readEvents();
				}
			},
			deep: true,
			immediate: true
		}

		// allUsers(){
		// 	if(allUsers.length){
		//
		// 	}
		// }
	},

	computed: {
		...mapGetters(["selfUser"]),

		eventToolTipStyles() {
			return (
				"top: " +
				(this.eventToolTip.y - 20) +
				"px; left: " +
				this.eventToolTip.x +
				"px;"
			);
		},

		/*
		isAfterErrorLive() {
			var em = JSON.parse(JSON.stringify(this.eventModal));

			em.startDate = moment(em.startDate).format("YYYY/MM/DD");
			em.endDate = moment(em.endDate).format("YYYY/MM/DD");

			var start =
				em.startDate + " " + em.start.h + ":" + em.start.m + " " + em.start.a;
			var end = em.endDate + " " + em.end.h + ":" + em.end.m + " " + em.end.a;

			console.log(start);
			console.log(end);

			// is the end date BEFORE the start date?
			if (moment(start).isAfter(moment(end))) {
				//this.isAfterError = true;
				// this.validationErrors = {
				// 	invalid_date:
				// 		"The end date and time must come after the start date and time."
				// };
				//alert("The end date must come after the start date.");
				return true;
			}

			return false;
		},
		*/

		loggedInUser() {
			return this.$store.state.user;
		},

		facilitiesManageSchedule() {
			// a subset of facilities that this user can make time blocks for
			if (!this.eventModalOptions.facilities) {
				return [];
			}

			var facilities = this.eventModalOptions.facilities.filter(function(ele) {
				console.log("ele");
				console.log(ele.manage_schedule);

				return ele.manage_schedule == true || ele.self_schedule == true;
			});

			return facilities;
		},

		// used for Schedule block modal//
		// not for filters
		usersForFacility() {
			if (this.eventModal.environment == "facility") {
				var selectedFacilityId = this.eventModal.facility;

				if (this.eventModalOptions.facilities) {
					var selectedFacility = this.eventModalOptions.facilities.find(
						function(ele) {
							return ele.id == selectedFacilityId;
						}
					);

					if (selectedFacility) {
						// if there's just one rt, default to that one
						if (selectedFacility.rts.length == 1) {
							var rtID = selectedFacility.rts[0].id;
							// eslint-disable-next-line vue/no-side-effects-in-computed-properties
							this.eventModal.rt = rtID;
						}

						return selectedFacility.rts;
					}
				}
			} else if (this.eventModal.environment == "rtnow") {
				let users = [];

				this.eventModalOptions.rtNOW_rt_manage_schedule.forEach(user => {
					if(user.pod === this.eventModalOptions.pod) {
					users.push(user);
					}
				});

				return users;

				// If want to show all rt regardless of the pod, uncomment below line and comment above code
				// return this.eventModalOptions.rtNOW_rt_manage_schedule;



				// return this.eventModalOptions.users;

				// var rtNowRts = this.eventModalOptions.users.filter(function(ele){
				//
				// 	console.log('ele');
				// 	console.log(ele.rtNow_rt);
				//
				// 	return (ele.rtNow_rt == true);
				//
				// });
				//
				// console.log('rtNowRts.length');
				// console.log(rtNowRts.length);

			}

			return [];
		},

		// based on options.users
		// these are users I can see but not nessisarily mange
		// limited to rtNOW RTs
		viewablertNowRts() {
			var rtNowRts = this.eventModalOptions.users.filter(function(ele) {
				console.log("ele");
				console.log(ele.rtnow_rt);

				return ele.rtnow_rt == true;
			});

			return rtNowRts;
		},
		// limited to all facility RTs
		viewableFacilityRTs() {
			var allFacilityRts = this.eventModalOptions.users.filter(function(ele) {
				console.log("ele");
				console.log(ele.rtnow_rt);

				return ele.rt_facilities.length;

				// rt_facilities[]
				//return (ele.id == this.scheduleFilter.facility_id);
				//return ele.rt_facilities.includes(this.scheduleFilter.facility_id);
				//return true;
			});

			return allFacilityRts;
		}
	},

	methods: {
		...mapActions([
			"ajax"
			//'getAllUsers'
		]),

		setEOD() {
			var sd = moment(this.eventModal.startDate)
				.add(1, "d")
				.format("MM/DD/YYYY");
			console.log(sd);
			this.$set(this.eventModal, "endDate", sd);

			// set time
			this.$set(this.eventModal, "end", {
				h: 12,
				m: "00",
				a: "AM"
			});

			this.EODsweller = true;
			setTimeout(() => {
				this.EODsweller = false;
			}, 600);
		},

		toggleGapMode(val) {
			this.gapMode = val;
			if (this.gapMode) {
				this.scheduleFilter.rt_id = this.selfUser.id;
				// setTimeout(() => {
				// 	this.showEnvironment = true;
				// }, 500);
			} else {
				this.scheduleFilter.rt_id = null;
			}
		},

		undoLastScheduleChange() {
			this.undoLastScheduleChangeMethod();

			console.log("this.eventToUndo");

			console.log(this.eventToUndo);

			this.updateEventAfterDrag(this.eventToUndo);

			this.showFooterUndo = false;
		},

		// click outside
		hideEnvironment() {
			this.showEnvironment = false;
		},

		hideTZ() {
			this.showTZ = false;
		},

		hideFacilities() {
			this.showFacilities = false;
		},

		hideRts() {
			this.showRts = false;
		},

		hidePods() {
			this.showPods = false;
		},

		//

		selectTimeZone(tz) {
			console.log("tz", tz);
			this.scheduleFilter.timezone = tz;
			this.eventModalOptions.timezone = tz;
			this.showTZ = false;
		},

		filterFacility(f) {
			if (f) {
				this.scheduleFilter.facility_id = f.id;
				this.scheduleFilter.facility_name = f.name;
			} else {
				// resetting to all Facilities
				this.scheduleFilter.facility_id = null;
				this.scheduleFilter.facility_name = "All Facilities";
			}
			this.showFacilities = false;
		},

		filterRts(rt) {
			// rt false means all RTs
			if (rt) {
				this.scheduleFilter.rt_id = rt.id;
				this.scheduleFilter.rt_name = rt.first_name + " " + rt.last_name;
			} else {
				// resetting to all RTs
				this.scheduleFilter.rt_id = null;
				this.scheduleFilter.rt_name = "All RTs";
			}

			this.showRts = false;
		},

		filterPods(pod) {
			// pod can't be false since it is required
			if (pod) {
				this.scheduleFilter.pod = pod.name;
			} else {
				console.log('error with pods')
			}

			this.showPods = false;
		},

		newEventModal(startDate, endDate) {
			console.log("Range selected " + startDate + " to " + endDate);

			this.eventModal.id = "";
			this.eventModal.environment = this.scheduleFilter.environment.toLowerCase();
			this.eventModal.rt = "";
			this.eventModal.facility = "";
			this.eventModal.mode = "new";
			this.eventModal.show = true;
			(this.eventModal.startDate = endDate
				? moment(startDate).format("MM/DD/YYYY")
				: moment().format("MM/DD/YYYY")),
				(this.eventModal.endDate = endDate
					? moment(endDate).format("MM/DD/YYYY")
					: moment().format("MM/DD/YYYY")),
				(this.eventModal.start = {
					h: endDate ? moment(startDate).format("h") : "8",
					m: endDate ? moment(startDate).format("mm") : "00",
					a: endDate ? moment(startDate).format("A") : "AM"
				});
			this.eventModal.end = {
				h: endDate ? moment(endDate).format("h") : "5",
				m: endDate ? moment(endDate).format("mm") : "00",
				a: endDate ? moment(endDate).format("A") : "PM"
			};
		},

		readOptions() {
			console.log("read events");

			this.ajax({
				url: "/schedule/options/",
				//data: {},
				success: json => {
					// if logged in user is an rtNOW admin or rtNOW RT,
					// default environment to 'rtNOW'
					// this code lives here in order to ensure that the loggedInUser object has loaded.

					if (this.loggedInUser.rtnow_admin || this.loggedInUser.rtnow_rt) {
						this.scheduleFilter.environment = "rtnow";
					}

					// load the event modal options to show all the pods to choose from
					this.eventModalOptions = json.options;
					this.eventModal.pod = json.options.pod;

					// show the events that are in the same pod as the use as a default
					this.scheduleFilter.pod = json.options.pod;

					//console.log('context', context);

					//this.readEvents();

					this.scheduleFilter.optionsLoaded = true;
				}
			});
		},

		async readEvents() {
			console.log("read events");

			this.ajax({
				url: "/schedule/get/" + this.viewYear + "/" + this.viewMonth,
				data: this.scheduleFilter,
				success: json => {
					this.events = json.events;
					this.eventModalOptions.pod = json.pod;
					this.eventModal.pod = json.pod;
					//this.scheduleFilter.timezone = json.timezone;
					//console.log('context', context);

					//this.assignColors();
				}
			});
		},

		deleteEvent(eventId, confirmed) {
			if (confirmed) {
				// find the object in thsi array where event id matches
				var selectedEvent = this.events.find(function(ele) {
					return ele.id == eventId;
				});

				// find the array index of this object
				var i = this.events.indexOf(selectedEvent);

				this.ajax({
					url: "/schedule/delete/" + eventId,
					//data: {},
					success: () => {
						// delete the array child at a given index
						this.$delete(this.events, i);
					}
				});

				return false;
			}

			// get confimation
			else {
				var c = confirm("Are you sure?");

				if (c) {
					this.deleteEvent(eventId, true);
				}
			}

			this.eventModal.show = false;
		},

		saveEvent() {
			console.log("saveEvent", this.eventModal.mode);

			if (this.eventModal.mode == "edit") {
				this.pushEvent();
				//this.updateEvent();
			} else {
				this.pushEvent();
			}
		},

		// update an existing event after dragging
		updateEventAfterDrag(myEvent) {
			// make a copy of this object for the ajax call
			var ajaxEvent = Object.assign({}, myEvent);

			ajaxEvent.start =
				moment(myEvent.start).format("YYYY/MM/DD h:mm A") +
				" " +
				this.eventModalOptions.timezone; //myEvent.start + ' ' + this.timezone;
			ajaxEvent.end =
				moment(myEvent.end).format("YYYY/MM/DD h:mm A") +
				" " +
				this.eventModalOptions.timezone; //myEvent.end + ' ' + this.timezone;
			ajaxEvent.source = null;
			ajaxEvent.className = null;

			ajaxEvent.facility =
				ajaxEvent.environment == "rtnow" ? "" : ajaxEvent.facility;

			this.ajax({
				validate: true,
				url: "/schedule/save/" + ajaxEvent.id,
				data: ajaxEvent,
				success: () => {
					ajaxEvent = null;
				}
			});
		},

		// edit an existing event
		updateEvent() {
			// optional event passed in

			//alert("updateEvent()");

			//var em = this.eventModal;

			var em = this.eventModal;

			console.log("em", em);
			//console.log(em);

			em.date = moment(em.date).format("YYYY/MM/DD");

			var start =
				em.date + " " + em.start.h + ":" + em.start.m + " " + em.start.a;
			var end = em.date + " " + em.end.h + ":" + em.end.m + " " + em.end.a;

			var selectedEvent = this.events.find(function(ele) {
				return ele.id == em.id;
			});

			selectedEvent.start = moment(start);
			selectedEvent.end = moment(end);

			selectedEvent.facility = em.facility;
			selectedEvent.rt = em.rt;

			// make a copy of this object for the ajax call
			var ajaxEvent = Object.assign({}, selectedEvent);

			ajaxEvent.start = start + " " + this.eventModalOptions.timezone;
			ajaxEvent.end = end + " " + this.eventModalOptions.timezone;

			ajaxEvent.facility =
				ajaxEvent.environment == "rtnow" ? "" : ajaxEvent.facility;

			this.ajax({
				validate: true,
				url: "/schedule/save/" + selectedEvent.id,
				data: em,
				success: () => {
					//this.events = json.events;
					//console.log('', context);
					this.validationErrors = [];
					//this.events.push(newEvent);
					ajaxEvent = null;

					this.readEvents();

					em.show = false;
				},
				invalid: json => {
					this.validationErrors = json.validation_errors;
				}
			});
		},

		// push a new event
		pushEvent() {
			this.isAfterError = false;

			var em = this.eventModal;

			em.startDate = moment(em.startDate).format("YYYY/MM/DD");
			em.endDate = moment(em.endDate).format("YYYY/MM/DD");

			var start =
				em.startDate + " " + em.start.h + ":" + em.start.m + " " + em.start.a;
			var end = em.endDate + " " + em.end.h + ":" + em.end.m + " " + em.end.a;

			// is the end date BEFORE the start date?
			if (moment(start).isAfter(moment(end))) {
				this.isAfterError = true;
				this.validationErrors = {
					invalid_date:
						"The end date and time must come after the start date and time."
				};
				//alert("The end date must come after the start date.");
				return false;
			}

			//var d = new Date();
			//var n = d.getTime();

			var newEvent = {
				title: "New Event",
				start: moment(start),
				end: moment(end),
				environment: em.environment,
				className: "fc-primary",
				editable: true,
				overlap: true,
				rt: em.rt,
				facility: em.facility,
				allDay: false,
				pod: this.eventModalOptions.pod,
			};

			// is the end date BEFORE the start date?

			this.events.push(newEvent);

			// for ajax
			newEvent.start = start + " " + this.eventModalOptions.timezone;
			newEvent.end = end + " " + this.eventModalOptions.timezone;

			console.log("newEvent", newEvent);

			//var event_id = (this.eventModal.mode == 'edit')?  : '';

			var event_id = "";

			if (this.eventModal.mode == "edit") {
				event_id = "/" + em.id;
			}

			newEvent.facility =
				newEvent.environment == "rtnow" ? "" : newEvent.facility;

			this.ajax({
				url: "/schedule/save" + event_id,
				data: newEvent,
				success: json => {
					console.log("json", json);

					em.show = false;
					//this.events = json.events;
					//console.log('context', context);
					this.validationErrors = {};

					this.readEvents();
					//
				},
				invalid: json => {
					this.validationErrors = json.validation_errors;
				}
			});
		}

		/*
  		removeEvent() {

			//this.$refs.calendar.$emit('remove-event', this.selected);
			//this.selected = {};
		},
		eventSelected(event) {
			//this.selected = event;
			console.log(test);

		},
		eventCreated(...test) {
			console.log(test);
		},
*/
	},

	mounted() {
		// setTimeout(()=>{
		// 	this.getAllUsers();
		// },1000);

		//this.readEvents();

		// default the filter environment to rtNOW

		this.readOptions();
	},

	components: {
		FullCalendar,
		Datepicker
	}
};
</script>

<style lang="scss">
.hasErrors {
	.form-control,
	select {
		border-color: #dc3545;
	}
}
.input-group.sweller,
.sweller input {
	animation: swell 0.3s infinite; /* remove 'infinate' if you just want 1 cycle */
}

@keyframes swell {
	from {
		transform: scale3d(1, 1, 1);
	}

	50% {
		transform: scale3d(1.05, 1.05, 1.05);
	}

	to {
		transform: scale3d(1, 1, 1);
	}
}

body .filterDot{
	left: 6px;
}
</style>
